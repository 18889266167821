import 'alpinejs'

window.$ = window.jQuery = require('jquery');
window.Swal = require('sweetalert2');
// CoreUI
require('@coreui/coreui');

// Boilerplate
require('../plugins');

// Datatables
require('datatables.net/js/jquery.dataTables.min');
require('datatables.net-bs4/js/dataTables.bootstrap4.min');
require('@chenfengyuan/datepicker/dist/datepicker.min');


//Tinymce
require('tinymce');
tinymce.baseURL = window.baseURL + '/assets/libs/tinymce';
tinymce.baseURI = new tinymce.util.URI(tinymce.baseURL);
tinymce.init({
    selector: '.editor',
    height: 480,
    menubar: false,
    statusbar: false,
    relative_urls: false,
    language: 'vi',
    plugins: [
        'advlist autolink lists link image charmap print preview hr anchor pagebreak',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime media nonbreaking save table directionality',
        'emoticons template paste textpattern',
        'table'
    ],
    toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media',
    toolbar2: 'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
    file_picker_callback: function (callback, value, meta) {
        var x = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
        var y = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;

        var cmsURL = '/admin/file-manager?editor=' + meta.fieldname;
        if (meta.filetype == 'image') {
            cmsURL = cmsURL + "&type=Images";
        } else {
            cmsURL = cmsURL + "&type=Files";
        }

        tinyMCE.activeEditor.windowManager.openUrl({
            url: cmsURL,
            title: 'ファイル管理',
            width: x * 0.8,
            height: y * 0.8,
            resizable: "yes",
            close_previous: "no",
            onMessage: (api, message) => {
                callback(message.content);
            }
        });
    },
    branding: false,
    setup: function (ed) {
        ed.on('change', function (e) {
            $('#' + ed.id).val(ed.getContent()).valid();
            $('#' + ed.id).trigger('change');
        });
    }
});



// Select
require('select2/dist/js/select2.full.min');
require('select2/dist/js/i18n/ja');
$.fn.select2.defaults.set('theme', 'bootstrap4');

$('[data-toggle="select2picker"]').select2();
$('[data-toggle="select2pickerDefault"]').select2({
    allowClear: true,
});

$('[data-toggle="select2tags"]').select2({
    theme: 'bootstrap4',
    tags: true,
    tokenSeparators: [",", " "],
    createTag: function (tag) {
        var term = $.trim(tag.term);

        if (term === '') {
            return null;
        }

        return {
            id: term,
            text: term,
            newTag: true
        }
    }
});

$('.select2-selection__rendered').on('mouseenter mouseleave', function () {
    $(this).removeAttr('title');
});

$('.select2-selection__choice').on('mouseenter mouseleave', function () {
    $(this).removeAttr('title');
});


$(document).on('input', ".sb-num", function () {
    var val = $(this).val();
    val = val.replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    val = val.replace(/[^0-9.]/g, '');
    $(this).val(val);
});

$(document).on('keyup', ".numeric", function () {
    if ($(this).val() !== '') {
        var n = parseInt($(this).val().replace(/\D/g, ''));
        $(this).val(n.toLocaleString('en-US'));
    }
});

// Ajax setup
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    }
});


//datatable length change
$('table').on('length.dt', function (e, settings, length) {
    let table = this;
    let id = $(table).attr('id');
    let lengthData = window.getLocalSession('lengthData') || {};
    if (lengthData.length > 0) {
        lengthData = JSON.parse(lengthData);
        if (lengthData.hasOwnProperty(id)) {
            lengthData[id].length = length;
        } else {
            lengthData[id] = { 'length': length };
        }
    } else {
        lengthData[id] = { 'length': length };
    }
    window.saveLocalSession('lengthData', JSON.stringify(lengthData));
}).on('preInit.dt', function (e, settings) {
    let table = this;
    let id = $(table).attr('id');
    let lengthData = getLocalSession('lengthData') || {};
    if (lengthData.length > 0) {
        lengthData = JSON.parse(lengthData);
        if (lengthData.hasOwnProperty(id)) {
            $(table).DataTable().page.len(lengthData[id].length);
        }
    }
});

$('table').on('click', 'tr', function (event) {
    if (!$(event.target).is('i, a, button') && !$(event.target).is('.not-clickable')) {
        let href = $(this).data('edit');
        if (href) {
            window.location.href = href;
        }
    }
});

//local session function
window.saveLocalSession = function saveLocalSession(key, value) {
    sessionStorage.setItem(key, value);
}

window.getLocalSession = function getLocalSession(key = '') {
    return sessionStorage.getItem(key);
}

//Jquery validation
// require('jquery-validation/dist/jquery.validate.min.js');

$.validator.addMethod('maxItem', function (value, element, param) {
    let countItem = $('.multiple-upload .preview-thumbnail').length - 1;
    let uploadItem = $(element).prop('files').length;
    return this.optional(element) || countItem + uploadItem <= param;
}, 'ファイルのアップロードの最大数は{0}アイテムです。');

$.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || ((($(element).prop('files')[0].size / 1024)) <= param)
}, '{0}KB以下のファイルを指定してください。');

$.validator.addMethod('dateFormat', function (value, element, param) {
    var check = false;
    var re = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    if (re.test(value)) {
        var adata = value.split('/');
        var dd = parseInt(adata[0], 10);
        var mm = parseInt(adata[1], 10);
        var yyyy = parseInt(adata[2], 10);
        var xdata = new Date(yyyy, mm - 1, dd);
        if ((xdata.getFullYear() === yyyy) && (xdata.getMonth() === mm - 1) && (xdata.getDate() === dd)) {
            check = true;
        }
        else {
            check = false;
        }
    } else {
        check = false;
    }
    return this.optional(element) || check;
}, 'Wrong date format');


//Loading screen
window.showLoading = function (status) {
    let loading = $('.app-loading');
    if (true === status) {
        $('body').css('overflow', 'hidden');
        loading.show();
    } else {
        $('body').css('overflow', '');
        loading.hide();
    }
}

// datepicker
$('.datepicker').datepicker({
    autoHide: true,
    format: 'dd/mm/YYYY'
});

// inputmask
require('jquery.inputmask/dist/jquery.inputmask.bundle');

$('[data-mask-currency]').inputmask('integer', {
    min: 0,
    radixPoint: '.',
    groupSeparator: ',',
    rightAlign: false,
    autoGroup: true,
    removeMaskOnSubmit: true,
    autoUnmask: true,
    allowPlus: false,
    allowMinus: false,
    prefix: "",
    suffix: ' VND',
});

$('[data-mask-number]').inputmask('integer', {
    min: 0,
    radixPoint: '.',
    groupSeparator: ',',
    rightAlign: false,
    autoGroup: true,
    removeMaskOnSubmit: true,
    autoUnmask: true,
    allowPlus: false,
    allowMinus: false,
});


window.ajaxGetDistrictsByCity = function (source, target) {
    source = $(source);
    target = $(target);

    source.on("change", function () {
        let id = source.val();
        $.ajax({
            url: "/api/districts/" + id,
            method: "get",
            beforeSend: function () {
                showLoading(true);
            },
            success: function (data) {
                let options = "";
                if (data.contents.length) {
                    for (let i = 0; i < data.contents.length; i++) {
                        options += `<option value="${data.contents[i].id}">${data.contents[i].name}</option>`;
                    }

                }
                target.find("option:not([disabled])").remove();
                target.append(options);
            },
            error: function () {
                console.log("error city: " + id);
            },
            complete: function () {
                showLoading(false);
            }
        });
    });
};
